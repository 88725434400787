import Select from "react-select";
import {useState} from "react";
import SelectRow from "./SelectRow";
import {Button, Form} from "react-bootstrap";
const AddConnection = ({template,setAlert,addConnection,loadTemplate})=>{

    const [source,setSource] = useState(null)
    const [destination, setDestination] = useState(null)
    const [disableButton,setDisableButton] = useState(false)
    const handleClick = async ()=>{
        setDisableButton(true)
        if ( !source || !destination){
            setDisableButton(false)
           return  setAlert({
                "variant":"danger",
                "message":"Must select a source and destination"
            })

        }
        addConnection(source.value,destination.value).then( (r)=>{
            setAlert(r.data)
            setDisableButton(false)
            setSource(null)
            setDestination(null)
            loadTemplate()
        }).catch( (e)=>{
            setAlert(e.response.data)
            setDisableButton(false)

        })

    }
    return (
        <div className="row">
            <div className="col-3">
                <SelectRow template={template} type={"SOURCE"} value={source} setValue={setSource}/>
            </div>
            <div className="col-3">
                <Form.Select aria-label="Default select example">
                    <option>Maps to</option>
                </Form.Select>
            </div>
            <div className="col-3">
                <SelectRow template={template} type={"DESTINATION"} value={destination} setValue={setDestination}/>
            </div>
            <div className="col-3">
                <Button variant={"primary"} onClick={handleClick} disabled={disableButton}>Add Connection</Button>
            </div>
        </div>
    )

}

export default AddConnection