import {useDropzone} from "react-dropzone";
import Flex from "../../components/common/Flex";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import {useCallback} from "react";

const UploadFile = ({verifyUploadFile})=>{
    let onDrop = useCallback( (acceptedFiles)=>{
        acceptedFiles.map( ( file)=>{

            verifyUploadFile(file)

        })
    },[])
    const { getRootProps, getInputProps,isDragActive} = useDropzone({onDrop});

    return (
        <>
            <div {...getRootProps({ className: 'dropzone-area py-6' })}>
                <input {...getInputProps({multiple:false})} />
                <Flex justifyContent="center">
                    <img src={cloudUpload} alt="" width={25} className="me-2" />
                    <p className="fs-0 mb-0 text-700">Drop your file here</p>
                </Flex>
            </div>

        </>
    );
}
export default UploadFile;