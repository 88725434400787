import PageHeader from "../../../components/common/PageHeader";
import FalconComponentCard from "../../../components/common/FalconComponentCard";
import {useEffect, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";

const  View = ()=>{


    const [templates, setTemplates] = useState(null);
    const navigate = useNavigate()


    let loadTemplates = async ()=>{

        let baseURL = window.localStorage.getItem("baseURL")

        return axios({
            url:baseURL +"/template",
            method:"GET",
            headers:{
                "authorization":"Bearer " + window.localStorage.getItem("authToken")
            }

        }).then( (r)=>{
            if ( r.data.templates === 0){
                navigate("/")
                return
            }
            setTemplates(r.data.templates)

        }).catch( (e)=>{

        })
    }
    useEffect(() => {
        loadTemplates()
    }, []);


    let cols = [
        {field: "id", headerName: "#"},
        {field:"templateName", headerName:"Template Name", flex:1},
        {field:"edit", headerName:"Editor", flex:1, renderCell: (param)=>{

            let handleClick = (e)=>{
                navigate(`/template/editor?id=${param.value}`)
            }
            return (
                <Button variant={"primary"} onClick={handleClick}>Edit</Button>
            )
        }},


    ]
    let rows = []
    if ( templates !== null && templates.length > 0){
        rows = templates.map ( (template,index)=>{
            return {
                id:index+1,
                templateName:template.TemplateName,
                edit:template.TemplateID,
            }
        })

    }

    return (

        <>


            <PageHeader
                title="New Template"
                description="Create a new Excel mapping Template"
                className="mb-3"
            >
            </PageHeader>
                <FalconComponentCard>
                    <FalconComponentCard.Body>
                        {
                            templates !== null && (
                                <DataGrid
                                    columns={cols}
                                    rows={rows}
                                    initialState={{
                                    pagination: { paginationModel: { pageSize: 5 } }
                                }}
                                          pageSizeOptions={[5, 10, 25]}
                                    />
                            )
                        }
                    </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    )
}

export default View;