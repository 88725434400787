import * as XLSX from "xlsx";
import Select from "react-select";
import {useEffect, useState} from "react";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import axios from "axios";

const SelectTemplate = ({setTemplate,setAlert,template})=>{

    const [allTemplates,setAllTemplates ] = useState(null)


    useEffect(() => {
        axios({
            url:window.localStorage.getItem("baseURL")+"/template/",
            method:"GET",
            headers:{
                "authorization":"Bearer " + window.localStorage.getItem("authToken")
            }
        }).then( (r)=>{
            let availableTemplates = r.data.templates.map( (template)=>{
                return {
                    value:template,
                    label:template.TemplateName
                }
            })
            setAllTemplates(availableTemplates)
        }).catch( (e)=>{
            setAlert(e.response.data)

        })
    }, []);

    return (<>
        {allTemplates !== null &&  <Select
            closeMenuOnSelect={false}
            options={allTemplates}
            placeholder='Select...'
            classNamePrefix="react-select"
            value={template}
            onChange={value => setTemplate(value)}
        />

}
</>);
}
export default SelectTemplate;