import PageHeader from "../../../components/common/PageHeader";
import FalconComponentCard from "../../../components/common/FalconComponentCard";
import {useEffect, useState} from "react";
import qs from "qs";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {Alert} from "react-bootstrap";
import DisplayHeaders from "./DisplayHeaders";
import AddConnection from "./AddConnection/AddConnection";
import ConnectionTable from "./ConnectionTable";
import ConnectionFlow from "./ConnectionFlow";
const Editor = ()=>{

    let navigate = useNavigate()
    const[template, setTemplate] = useState(null)
    const[alert, setAlert] = useState({
        variant:"",
        "message":""
    })

    const loadTemplate = async ()=>{
        let search = window.location.search
        let params = qs.parse(search,{
            ignoreQueryPrefix:true
        })
        if ( !params.id){
            console.log("ID Not supplied")
            navigate("/")
            return
        }
        let id = params.id
        let baseURL=  window.localStorage.getItem("baseURL")
        return axios({
            "url":`${baseURL}/template/get-template?id=${id}`,
            method:"GET",
            headers:{
                "authorization":"Bearer " + window.localStorage.getItem("authToken")
            }
        }).then( (r)=>{

            setTemplate(r.data.template)

        }).catch( (e)=>{
            console.log(e.message.data)
            navigate("/")

        })
    }
    useEffect(() => {
       loadTemplate()

    }, []);

    const addConnection = async (sourceRow,destinationRow)=>{
        let baseURL = window.localStorage.getItem("baseURL")

        return axios({
            url:baseURL + "/template/editor/modify",
            method:"POST",
            headers:{
                "authorization":"Bearer " + window.localStorage.getItem("authToken")
            },
            data:{
                "type":"add",
                templateID:template.TemplateID,
                sourceRow,
                destinationRow
            }
        })
    }
    const deleteConnection = async (id) =>{
        let baseURL = window.localStorage.getItem("baseURL")

        return axios({
            url:baseURL + "/template/editor/modify",
            method:"POST",
            headers:{
                "authorization":"Bearer " + window.localStorage.getItem("authToken")
            },
            data:{
                "type":"delete",
                templateID:template.TemplateID,
                connectionID:id
            }
        })

    }

    return (

        <>

        <PageHeader
            title="Edit Template"
            description="Create Connections with template editors"
            className="mb-3"
        >
        </PageHeader>
        <FalconComponentCard>
            {alert.message.length > 0 && <Alert variant={alert.variant}>{alert.message}</Alert> }
        </FalconComponentCard>

            <FalconComponentCard>
                <FalconComponentCard.Body>
                    {template !== null && <ConnectionFlow template={template} setAlert={setAlert} addConnection={addConnection} loadTemplate={loadTemplate} /> }
                </FalconComponentCard.Body>
            </FalconComponentCard>


            <FalconComponentCard>
                <FalconComponentCard.Body>
                    {template !== null && <AddConnection template={template} setAlert={setAlert} addConnection={addConnection} loadTemplate={loadTemplate}/>}
                </FalconComponentCard.Body>
            </FalconComponentCard>


            <FalconComponentCard>
                <FalconComponentCard.Body>
                    {template !== null &&
                        <ConnectionTable
                        template={template}
                        setAlert={setAlert}
                        deleteConnection={deleteConnection}
                        loadTemplate={loadTemplate}
                        showDelete={true}
                        />}
                </FalconComponentCard.Body>
            </FalconComponentCard>



        </>
    )


}
export default Editor