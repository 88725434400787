import React, {useRef, useState} from "react";
import {Button, Form, Row, Col, Container, Table} from "react-bootstrap";
import * as XLSX from "xlsx";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const SaveTemplate = ({ workbook,setAlert }) => {
    // Reference for the input field
    const templateNameRef = useRef();
    const [disableButton,setDisableButton] = useState()
    let navigate = useNavigate()
    const getHeaders = (sheetName) => {
        const sheet = workbook.Sheets[sheetName];


        if (!sheet) return [];
        let sheetToJSON = XLSX.utils.sheet_to_json(sheet,{
            header:1
        })
        return sheetToJSON[0]
    };

    // Function to handle the Save and Begin Editor button click
    const handleClick = () => {
        setDisableButton(true)
        setAlert({
            variant:"",
            message:""
        })
        const templateName = templateNameRef.current.value;

        if (!templateName) {
            setDisableButton(false)
            return setAlert({
                "variant":"danger",
                "message":"Template name cannot be empty"
            })
        }
        const sourceHeaders = getHeaders("SOURCE");
        const destinationHeaders = getHeaders("DESTINATION");
        let baseURL = window.localStorage.getItem("baseURL")

        axios({
            url:`${baseURL}/template/new`,
            method:"POST",
            headers:{
                "authorization":"Bearer " + window.localStorage.getItem("authToken")
            },
            data:{
                templateName,
                sourceHeaders,
                destinationHeaders
            }
        }).then ( (r)=>{
            setAlert(r.data)

            setTimeout( ()=>{
                navigate(`/template/editor?id=${r.data.templateID}`)
            },2000)
        }).catch( (e)=>{
            setAlert(e.response.data)
        })

    };

    // Extract headers for rendering


    const sourceHeaders = getHeaders("SOURCE");
    const destinationHeaders = getHeaders("DESTINATION");

    return (<Container>
            <Row className="mb-3">
                <Col>
                    <h3>SOURCE Headers</h3>
                    <div style={{overflowX: "auto"}}>

                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                {sourceHeaders.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                            </thead>
                        </Table>
                    </div>
                </Col>
            </Row>

        <Row className="mb-3">
                <Col>
                    <h3>DESTINATION Headers</h3>
                    <div style={{overflowX: "auto"}}>

                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                {destinationHeaders.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}

                            </tr>
                            </thead>
                        </Table>
                    </div>
                </Col>
            </Row>

        <Row className="mb-4">
                <Col md={6}>
                    <Form.Group controlId="templateName">
                    <Form.Label>New Template Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter template name"
                            ref={templateNameRef}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button variant="primary" onClick={handleClick} disabled={disableButton}>
                        Save and Begin Editor
                    </Button >
                </Col>
            </Row>
        </Container>
    );
};

export default SaveTemplate;
