export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Template',
      active: true,
      children: [
        {
          name: 'Create new Template',
          to: '/template/new',
          exact: true,
          active: true
        },
        {
          name: 'View Template',
          to: '/template/view',
          exact: true,
          active: true
        },
        ]
    },
  ]
};
export const appRoutes = {
  label: 'app',
  labelDisable: true,
  children: [
    {
      name: 'Export',
      active: true,
      children: [
        {
          name: 'Create new Export',
          to: '/export/create-export',
          exact: true,
          active: true
        },

      ]
    },
  ]
};

export const pagesRoutes = {
  label: 'pages',
  labelDisable: true,

  children: []
};

export const modulesRoutes = {
  label: 'Modules',
  labelDisable: true,

  children: []
};

export const documentationRoutes = {
  label: 'documentation',
  labelDisable: true,
  children: []
};

export default [
  dashboardRoutes,
  appRoutes,
  pagesRoutes,
  modulesRoutes,
  documentationRoutes
];
