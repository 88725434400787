import {useState} from "react";
import Select from "react-select";
import * as XLSX from "xlsx"

const SelectRow = ({template, type,value,setValue})=>{

    let rows = type === "SOURCE" ? template.TemplateSourceHeaders : template.TemplateDestinationHeaders

    const rowOptions = rows.map( (row,index)=>{
        return {
            value:index,
            label:`${row} (${XLSX.utils.encode_cell({
                c:0,
                r:index
            })})`
        }
    })

    return (
        <Select
            closeMenuOnSelect={false}
            options={rowOptions}
            placeholder='Select...'
            classNamePrefix="react-select"
            value={value}
            onChange={value => setValue(value)}
        />
    );

}
export default SelectRow;