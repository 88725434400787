import {useState} from "react";
import PageHeader from "../../components/common/PageHeader";
import FalconComponentCard from "../../components/common/FalconComponentCard";
import SelectTemplate from "./SelectTemplate";
import {Alert} from "react-bootstrap";
import DisplayHeaders from "../Template/Editor/DisplayHeaders";
import ConnectionTable from "../Template/Editor/ConnectionTable";
import UploadFile from "../Common/UploadFile";
import * as XLSX from "xlsx";
const templateToWorkbook = (template, workBook)=>{

    template = template.value
    let sourceRequiredHeaders = template.TemplateSourceHeaders
    let destinationRequiredHeaders = template.TemplateDestinationHeaders


    let workbookSourceSheet = XLSX.utils.sheet_to_json(workBook.Sheets['SOURCE'],{
        header:1
    })
    let workbookDestinationSheet = XLSX.utils.sheet_to_json(workBook.Sheets['DESTINATION'],{
        header:1,
    })
    console.log(sourceRequiredHeaders)
    if ( workbookSourceSheet[0].length !== sourceRequiredHeaders.length){
        return {
            "variant":"danger",
            "message":"Required source headers do not match given workbook"
        }
    }
    if ( workbookDestinationSheet[0].length !== destinationRequiredHeaders.length){
        return {
            "variant":"danger",
            "message":"Required source headers do not match given workbook"
        }
    }
    let output = []
    output.push(workbookDestinationSheet[0])
    for ( let i = 1; i < workbookSourceSheet.length; i++){
        let nextDestinationRow = new Array(workbookDestinationSheet[0].length)

        for ( let j =0; j < template.Connections.length; j++){
            let connectionSourceRow = template.Connections[j]['SourceRow']
            let connectionDestinationRow = template.Connections[j]['DestinationRow']

            let valueToInsert = workbookSourceSheet[i][connectionSourceRow] === false ? " " : workbookSourceSheet[i][connectionSourceRow]
            nextDestinationRow[connectionDestinationRow] = valueToInsert
        }
        output.push(nextDestinationRow)
    }
    let newSheet = XLSX.utils.json_to_sheet(output, {
        skipHeader:true
    })
    XLSX.utils.book_append_sheet(workBook,newSheet,"OUTPUT")
    XLSX.writeFile(workBook, "output.xlsx")



}
const CreateExport = ()=>{


    let [template,setTemplate] = useState(null)
    let [alert,setAlert] = useState({
        "variant":"",
        "message":""
    })
    const verifyUploadFile = (file)=>{
        setAlert({
            "variant":"",
            "message":""
        })
        file.arrayBuffer().then( (buf)=>{
            let workBook = XLSX.readFile(buf)
            let sheetNames = workBook.SheetNames;
            if ( !sheetNames || !Array.isArray(sheetNames) ){
                return setAlert({
                    "variant":"danger",
                    "message":"Bad file"
                })
            }
            if ( sheetNames.length != 2){
                return setAlert({
                    "variant":"danger",
                    "message":"File requires a SOURCE and DESTINATION worksheet"
                })

            }
            if ( !sheetNames.includes("SOURCE") || !sheetNames.includes("DESTINATION") ){
                return setAlert({
                    "variant":"danger",
                    "message":"File requires a SOURCE and DESTINATION worksheet"
                })
            }
            templateToWorkbook(template, workBook)
        })
    }

    return (
        <>
            <PageHeader
                title="Export"
                description="Export excel file based on created template"
                className="mb-3"
            >
            </PageHeader>
            {alert.message.length > 0 && <Alert variant={alert.variant}>{alert.message}</Alert> }

            <FalconComponentCard>
                <FalconComponentCard.Body>
                    {template === null && <SelectTemplate setTemplate={setTemplate} setAlert={setAlert} /> }
                    {template !== null && <UploadFile verifyUploadFile={verifyUploadFile} /> }
                    {template !== null && <ConnectionTable template={template.value} showDelete={false} />}
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>

    )

}

export default CreateExport;
