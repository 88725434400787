import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from "xlsx"
import {Button} from "react-bootstrap";



const DeleteButton = ({deleteConnection,id, loadTemplate, setAlert})=>{

    const  handleClick = ()=>{
        setAlert({
            variant:"",
            "message":""
        })
        deleteConnection(id).then( (r)=>{
            setAlert(r.data)
            loadTemplate()
        }).catch( (e)=>{
            setAlert(e.response.data)
            loadTemplate()
        })
    }

    return (
        <Button variant={"danger"} onClick={handleClick}>Delete</Button>
    )



}
const ConnectionTable = ({template,deleteConnection, loadTemplate, setAlert, showDelete})=>{


    let cols = [
        {field: "id" , headerName: "#"},
        {field:"sourceRow", headerName:"Source Row", flex:1},
        {field:"destinationRow", headerName:"Destination Row", flex:1},

    ]
    if ( showDelete ){
        cols.push( {field:"delete", headerName:"Delete", renderCell: (param)=>{
                return <DeleteButton deleteConnection={deleteConnection}  id={param.value} loadTemplate={loadTemplate} setAlert={setAlert}/>
            }})
    }
    let rows = template.Connections.length === 0 ? [] : template.Connections.map( (connection,index)=>{
        let sourceRowIndex = connection.SourceRow;
        let sourceRowName = template.TemplateSourceHeaders[sourceRowIndex]
        let sourceRowCell = XLSX.utils.encode_cell({
            c:sourceRowIndex,
            r:0
        })

        let destinationRowIndex = connection.DestinationRow;
        let destinationRowName = template.TemplateDestinationHeaders[destinationRowIndex]
        let destinationRowCell = XLSX.utils.encode_cell({
            c:destinationRowIndex,
            r:0
        })
        return {
            id:index+1,
            sourceRow: `${sourceRowName}(${sourceRowCell})`,
            destinationRow: `${destinationRowName}(${destinationRowCell})`,
            delete:`${connection._id}`

        }
    })

    return (
        <DataGrid
            columns={cols}
            rows={rows}
            initialState={{
                pagination: { paginationModel: { pageSize: 5 } }
            }}
            pageSizeOptions={[5, 10, 25]}
            />
    )



}

export default ConnectionTable;