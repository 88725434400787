import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';




import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import New from "../ExcelMigratorComponents/Template/New/New";
import Editor from "../ExcelMigratorComponents/Template/Editor/Editor";
import CreateExport from "../ExcelMigratorComponents/Export/CreateExport";
import View from "../ExcelMigratorComponents/Template/View/View";
import AuthSimpleLayout from "../layouts/AuthSimpleLayout";
import Login from "../ExcelMigratorComponents/Auth/Login/Login";

const FalconRoutes = () => {

  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      {/*- ------------- Authentication ---------------------------  */}

      {/*- ------------- simple ---------------------------  */}


      {/*- ------------- Card ---------------------------  */}


      {/*- ------------- Split ---------------------------  */}

      {/* //--- MainLayout Starts  */}
        <Route element={<AuthSimpleLayout />}>
            <Route path="/auth/login" element={<Login />} />
        </Route>
      <Route element={<MainLayout />}>
        {/*Dashboard*/}
          <Route path="/template/new" element={<New />} />
          <Route path="/template/view" element={<View />} />

          <Route path="/template/editor" element={<Editor />} />
          <Route path="/export/create-export" element={<CreateExport />} />

      </Route>

      {/* //--- MainLayout end  */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/auth/login" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
