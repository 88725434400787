import PageHeader from "../../../components/common/PageHeader";
import {useState} from "react";
import UploadFile from "../../Common/UploadFile";
import {Accordion, Alert} from "react-bootstrap";
import FalconComponentCard from "../../../components/common/FalconComponentCard";
import * as XLSX from "xlsx";
import SaveTemplate from "./SaveTemplate";
const New = ()=>{


    const [workBook, setWorkBook] = useState(null)
    const [alert, setAlert] = useState({
        "variant":"",
        "message":""
    })
    const verifyUploadFile = (file)=>{
        setAlert({
            "variant":"",
            "message":""
        })
        file.arrayBuffer().then( (buf)=>{
            let workBook = XLSX.readFile(buf)
            let sheetNames = workBook.SheetNames;
            if ( !sheetNames || !Array.isArray(sheetNames) ){
                return setAlert({
                    "variant":"danger",
                    "message":"Bad file"
                })
            }
            if ( sheetNames.length != 2){
                return setAlert({
                    "variant":"danger",
                    "message":"File requires a SOURCE and DESTINATION worksheet"
                })

            }
            if ( !sheetNames.includes("SOURCE") || !sheetNames.includes("DESTINATION") ){
                return setAlert({
                    "variant":"danger",
                    "message":"File requires a SOURCE and DESTINATION worksheet"
                })
            }
            setWorkBook(workBook)

        })
    }



    return (

        <>
            <PageHeader
                title="New Template"
                description="Create a new Excel mapping Template"
                className="mb-3"
            >
            </PageHeader>
            <FalconComponentCard>
                <FalconComponentCard.Body>
                    {alert.message.length > 0 && <Alert variant={alert.variant}>{alert.message}</Alert> }
                    <Accordion activeKey={workBook === null ? "0" : "1"}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Upload SOURCE DESTINATION file </Accordion.Header>
                            <Accordion.Body>
                                {workBook === null && <UploadFile verifyUploadFile={verifyUploadFile}/>}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Save template</Accordion.Header>
                            <Accordion.Body>
                                {workBook !== null && <SaveTemplate workbook={workBook} setAlert={setAlert}/>}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </FalconComponentCard.Body>
            </FalconComponentCard>

        </>
    )

}
export default New;