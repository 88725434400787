import ReactFlow, {
    Background,
    Controls,
    MiniMap,
    MarkerType, useNodesState, useEdgesState,
} from "reactflow";
import { Container } from "react-bootstrap";
import { v4 } from "uuid";
import "reactflow/dist/style.css";
import {useCallback} from "react";
import * as XLSX from "xlsx"
const getEdges = (template, sourceNodes, destinationNodes) => {
    let connections = template.Connections;

    let edges = [];

    for (let i = 0; i < connections.length; i++) {
        let sourceRow = connections[i].SourceRow;
        let destinationRow = connections[i].DestinationRow;
        edges.push({
            id: connections[i]._id.toString(),
            source: sourceNodes[sourceRow].id,
            target: destinationNodes[destinationRow].id,
            animated: true,
            style: { stroke: "#42a5f5" },
            markerEnd: {
                type: MarkerType.ArrowClosed,
                color: "#42a5f5",
            },
        });
    }

    return edges;
};

const ConnectionFlow = ({ template,setAlert,addConnection , loadTemplate}) => {

    const sourceHeaderNodes = template.TemplateSourceHeaders.map(
        (sourceHeader, index) => ({
            id: v4(),
            data: { label: <><strong>{sourceHeader}</strong>({XLSX.utils.encode_cell({
                    c:index,
                    r:0
                })})<br/>(source)</> },
            position: { x: 150 * index, y: 0 },
            style: {
                backgroundColor: "#e3f2fd",
                border: "1px solid #42a5f5",
                borderRadius: "5px",
                padding: "10px",
            },
        })
    );

    const destinationHeaderNodes = template.TemplateDestinationHeaders.map(
        (destinationHeader, index) => ({
            id: v4(),
            data: { label: <><strong>{destinationHeader}</strong>({XLSX.utils.encode_cell({
                    c: index,
                    r: 0
                })})<br/><small>(destination)</small></>
            },
            position: { x: 150 * index, y: 200 },
            style: {
                backgroundColor: "#e8f5e9",
                border: "1px solid #66bb6a",
                borderRadius: "5px",
                padding: "10px",
            },
        })
    );

    const initialNodes = sourceHeaderNodes.concat(destinationHeaderNodes);
    const initialEdges = getEdges(template, sourceHeaderNodes, destinationHeaderNodes);




    const handleConnect = useCallback( (connection)=>{
        setAlert({
            variant:"",
            message:""
        })
        let nodeIDOne = connection.source;
        let nodeIDTwo = connection.target;

        let sourceRow = sourceHeaderNodes.findIndex ( (node)=>{
            return node.id === nodeIDOne
        })
        if ( sourceRow === -1){
           sourceRow =  destinationHeaderNodes.findIndex( (node)=>{
               return node.id === nodeIDOne
           })
        }
        if ( sourceRow === -1){
            console.log("Could not find source")
            return setAlert({
                variant:"danger",
                message:"Bad connection"
            })
        }

        let destinationRow = destinationHeaderNodes.findIndex ( (node)=>{
            return node.id === nodeIDTwo
        })
        if ( destinationRow === -1){
            destinationRow =  sourceHeaderNodes.findIndex( (node)=>{
                return node.id === nodeIDTwo
            })
        }
        if ( destinationRow === -1){
            return setAlert({
                variant:"danger",
                message:"Bad connection"
            })
        }
        addConnection(sourceRow,destinationRow).then(
            (r)=>{
            setAlert(r.data)
            loadTemplate()
        }).catch( (e)=>{
            setAlert(e.response.data)

        })



    },[template])
    return (
        <Container
            style={{
                width: "100%",
                height: "500px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#f5f5f5",
            }}
        >
            <ReactFlow
                nodes={initialNodes}
                edges={initialEdges}
                onConnect={handleConnect}
                fitView
                nodeTypes={{ custom: () => <div /> }}
                edgeTypes={{ custom: () => <div /> }}
            >
                <Background color="#ddd" gap={16} />
                <Controls />
            </ReactFlow>
        </Container>
    );
};

export default ConnectionFlow;
