import React, {useRef, useState} from 'react';
import {Alert, Button, Col, Form, Row} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
const LoginForm = ({ hasLabel, layout }) => {
    // State

    const [alert,setAlert] = useState({
        "variant":"",
        "message":""
    })
    const [buttonDisable,setButtonDisable] = useState(false)

    const navigate = useNavigate()

    const emailRef = useRef()
    const passwordRef = useRef()
    // Handler
    const handleSubmit = e => {
        e.preventDefault();
        setButtonDisable(true)
        setAlert({
            "variant":"",
            "message":""
        })
        let email = emailRef.current.value
        let password = passwordRef.current.value

       axios({
           url:window.localStorage.getItem("baseURL") + "/auth/login",
           method:"post",
           data:{
               email,
               password
           }
       }).then( (r)=>{
           window.localStorage.setItem("authToken",r.data.token)
           console.log(r.data)
           setAlert({
               variant: "success",
               "message":"Successfully logged in please wait to be redirected"
           })

           setTimeout( ()=>{
               navigate("/template/new")
           },2000)
       }).catch( (e)=>{

           setAlert(e.response.data)
           console.log(e)
           setButtonDisable(false)

       })

    };

    const handleFieldChange = e => {

    };

    return (
        <Form onSubmit={handleSubmit}>
            {alert.message.length > 0 && <Alert variant={alert.variant}>{alert.message}</Alert> }

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Email address</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Email address' : ''}
                    ref={emailRef}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Password</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Password' : ''}
                    ref={passwordRef}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                />
            </Form.Group>


            <Form.Group>
                <Button
                    type="submit"
                    color="primary"
                    className="mt-3 w-100"
                >
                    Log in
                </Button>
            </Form.Group>

        </Form>
    );
};

export default LoginForm;
